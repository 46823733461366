<template>
    <v-container class="publish-module-card">
        <amp-publish />
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { AmpPublish } from '@/components/AmpModule';

@Component({
    components: {
        AmpPublish
    }
})
export default class Publish extends Vue {
    mounted() {
        this.$store.dispatch(
            'cachekey/generate',
            this.$route.params.announcementId
        );
    }
}
</script>

<style lang="scss">
.publish-module-card {
    .v-card {
        border-top: 6px solid $faded-blue;
    }
}
</style>
